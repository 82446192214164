<template>
  <!-- fd-footer -->
  <footer class="fd-footer">
    <div class="fdn-footer-top">
      <div class="container">
        <div class="fdn-footer-content">
          <div class="fdn-footer-content-brand">
            <img
              v-lazy="imageSiteLogoHorizontal"
              rel="FastEAD"
            >
            <p>Empresa de sociedade limitada registrada sob o CNJP 32.221.157/0001-33 e Razão Social Consultoria Em I nformatica e Servicos Educacionais Concurseiro Sociedade Empresaria Ltda. Entregamos soluções para ensino a distância para o mercado B2B e B2C.</p>
          </div>
          <div class="fdn-footer-content-menu">
            <strong>Menu</strong>
            <ul class="fdn-footer-content-menu-list">
              <li class="fd-nav-header-nav-item">
                <a href="fastead_empresas.html">Para empresas</a>
              </li>
              <li class="fd-nav-header-nav-item">
                <a href="fastead_escolas.html">Ensino formal</a>
              </li>
              <li>
                <a href="cursos_ondemand.html">Cursos on-demand</a>
              </li>
              <li>
                <a href="https://api.whatsapp.com/send?phone=+5511994478241&text=Ol%C3%A1%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto">Seja representante</a>
              </li>
              <li>
                <a
                  href="#modalLogin"
                  data-toggle="modal"
                  data-target="#modalLogin"
                >Acessar</a>
              </li>
              <li>
                <a href="cadastro_plataforma.html">Entre grátis</a>
              </li>
            </ul>
          </div>
          <div class="fdn-footer-content-blog">
            <strong>últimas postagens do blog</strong>
            <ul
              v-for="noticias in noticiasBlog"
              id="listaUltimasBlog"
              :key="noticias[0]"
              class="fdn-footer-content-blog-posts"
            >
              <li class="fdn-footer-content-blog-posts-item">
                <div
                  class="fdn-footer-content-blog-posts-item-image"
                  :style="{ backgroundImage: 'url(https://concurseiro.com/' + noticias[2] + ')' }"
                />
                <div class="fdn-footer-content-blog-posts-item-text">
                  <a href="#">
                    <strong>Titulo</strong>
                  </a>
                  <p>Lorem ipsum</p>
                </div>
              </li>
            </ul>
          </div>
          <div class="fdn-footer-content-contact">
            <strong>Contatos</strong>
            <ul class="fdn-footer-content-contact-list">
              <li>
                <img v-lazy="imageSiteMapIcon">
                <span>R. JARAGUA - PORTO ALEGRE, RS Brasil</span>
              </li>
              <li>
                <img v-lazy="imageSitePhoneIcon">
                <span>+55 (51) 3109-3000</span>
              </li>
              <li>
                <a
                  href="https://api.whatsapp.com/send?phone=+5511994478241&text=Ol%C3%A1%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto"
                  target="_blank"
                  class="btn btn-primary"
                >Fale com um consultor</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="fdn-footer-bottom">
      <div class="container">
        <span>Todos os direitos reservados @ 2019</span>
      </div>
    </div>
  </footer>
  <!-- /fd-footer -->
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import imageSiteLogoHorizontal from "@/assets/images/logo_horizontal.png";
import imageSiteMapIcon from "@/assets/images/map-icon.png";
import imageSitePhoneIcon from "@/assets/images/phone-icon.png";

export default {
  mixins: [methods],
  data: function() {
    return {
      imageSiteLogoHorizontal,
      imageSiteMapIcon,
      imageSitePhoneIcon,
      noticiasBlog: [],
    };
  },
  mounted() {
    if (settings.endApiConcurseiro == "https://api.concurseiro.com/") {
      this.listaUltimasBlog();
    }
  },
  methods: {
    async listaUltimasBlog() {
      try {
        let resp = await fetch(settings.endApiConcurseiro + "api/cargos/listablog", this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let arrayNoticiasBlog = Array.from(json).map((e) => e.ItemArray);
        this.noticiasBlog = arrayNoticiasBlog.slice(0, 3);
        //console.log(this.noticiasBlog);
      } catch (e) {
        console.log("Erro", e);
      }
    },
  },
};
</script>

<style></style>
